.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.text-container {
  text-align: right;
  max-width: 60%;
  margin-top: 30px;
}

.title {
  font-family: signika;
  font-size: 2rem;
  color: #254552;
}

.subtitle {
  font-family: montserrat;
  font-size: 0.7rem;
  color: #254552;
}

.title,
.subtitle {
  position: relative;
  z-index: 3;
}

.wrap-welcome {
  position: relative;
  width: 400px;
  height: 300px;
}

.wrap {
  position: relative;
}

.mascot {
  width: 85%;
  height: auto;
  z-index: 2;
}

.large-circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #264653;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.circle {
  position: absolute;
  border-radius: 50%;
}

.circle1 {
  width: 130px;
  height: 130px;
  background-color: #e56f51;
  top: 150px;
  left: -100px;
  z-index: 2;
}

.circle2 {
  width: 110px;
  height: 110px;
  background-color: #f2a15f;
  top: -10px;
  left: -50px;
  z-index: 2;
}

.circle3 {
  width: 80px;
  height: 80px;
  background-color: #e8c36a;
  top: -20px;
  right: 180px;
  z-index: 2;
}

.red-gear {
  position: absolute;
  width: 250px;
  height: 250px;
  bottom: 0px;
  right: -80px;
}
