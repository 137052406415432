#root {
  min-height: 100%;
}
header {
  z-index: 400;
}
img {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Non-prefixed version */
}
.gear-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  transform: translate(-50%, -50%);
}

.gear {
  position: absolute;
  top: 0;
  left: 0;
  width: 370px;
  height: 370px;
}

@keyframes rotateGear {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.base {
  z-index: 90;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, 0%);
  background-color: #f2a15f;
  width: 98px;
  height: 98px;
  transition: all 1s cubic-bezier(0.5, -0.75, 0.05, 1);
  border-bottom-right-radius: 100%;
}

.menu {
  z-index: 100;
  background-color: #e9c46a;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-bottom-right-radius: 250px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.5, -0.75, 0.05, 1), background-color 1s ease;
}

.icon {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.bar {
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #ffffff;
  transition: transform 0.5s ease, opacity 0.25s ease;
}

.bar1 {
  top: 0;
}

.bar2 {
  top: 10px;
}

.bar3 {
  top: 20px;
}

.rotate-bar1 {
  transform: rotate(45deg);
  top: 5px;
}

.rotate-bar2 {
  transform: rotate(-45deg);
  top: 5px;
}

.base.close .bar1,
.base.close .bar3 {
  opacity: 0;
}

.icons {
  z-index: 90;
  position: absolute;
  top: 0;
  left: 0;
}

.icons > * {
  position: absolute;
  font-size: 40px;
  transition: 0.3s cubic-bezier(0.5, -0.25, 0.05, 1);
}

.menu-icon-1 {
  top: 35px;
  left: 0;
  transition-delay: 0.2s;
}

.menu-icon-2 {
  top: 25px;
  left: 0;
  color: #fff;
  transition-delay: 0.1s;
}

.menu-icon-3 {
  top: 0;
  left: 35px;
}

.section {
  z-index: 89;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  transform-origin: 100% 100%;
  transform: rotate(135deg);
}

.cover1 {
  transform-origin: 100% 100%;
}

.cover1,
.cover2,
.cover2 .content {
  position: absolute;
  width: 600px;
  height: 600px;
}

.cover1,
.cover2 {
  top: 50%;
  left: 50%;
  transform: translate(-100%, -100%) rotate(4deg);
  overflow: hidden;
  pointer-events: none;
  transition: transform 0.5s ease-in;
}

.cover2 {
  transform: translate(50%, -50%) rotate(-8deg);
  transform-origin: 0% 100%;
}

.cover2 .content {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: #e56f51;
  top: 100%;
  left: 0%;
  transform: translate(-50%, -50%);
  transition: background-color 0s,
    width 1.5s cubic-bezier(0.5, -0.5, 0.05, 1) 0s,
    height 1.5s cubic-bezier(0.5, -0.5, 0.05, 1) 0s;
  pointer-events: auto;
}

.section-static {
  z-index: 94;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0% 0%;
  transition: width 1s cubic-bezier(0.5, -0.75, 0.05, 1),
    height 1s cubic-bezier(0.5, -0.75, 0.05, 1);
}

.section-static:hover {
  background-color: #eaeaea;
}

.section-static.top {
  transform: rotate(-45deg);
  border-bottom-right-radius: 400px;
}

.section-static.bottom {
  transform: rotate(45deg);
  border-bottom-right-radius: 400px;
}

.base.close {
  width: 300px;
  height: 300px;
  transition: all 1.25s cubic-bezier(0.5, 0, 0.05, 1.75);
}

.base.close .menu {
  width: 150px;
  height: 150px;
  transition: all 1s cubic-bezier(0.5, 0, 0.05, 1.75), background-color 1s ease;
  background-color: #e9c46a;
}

.base.close .icons > * {
  font-size: 40px;
  color: #ffffff;
  transition: 0.3s cubic-bezier(0.5, 0, 0.05, 1.75) 0.7s;
  z-index: 999;
}

.base.close .menu-icon-1 {
  top: 35px;
  left: 200px;
  z-index: 999;
}

.base.close .menu-icon-2 {
  top: 141px;
  left: 141px;
  color: #fff;
  transition-delay: 0.65s;
  z-index: 999;
}

.base.close .menu-icon-3 {
  top: 200px;
  left: 35px;
  transition-delay: 0.8s;
  z-index: 999;
}

.base.close .section .cover1,
.base.close .cover2,
.base.close .cover2 .content {
  position: absolute;
  width: 600px;
  height: 600px;
}

.base.close .cover1,
.base.close .cover2 {
  top: 50%;
  left: 50%;
  transform: translate(-100%, -100%) rotate(16deg);
  overflow: hidden;
  transition: transform 0.5s ease-in 0.5s;
}

.base.close .cover2 {
  transform: translate(50%, -50%) rotate(-32deg);
  transform-origin: 0% 100%;
}

.base.close .cover2 .content {
  border-radius: 100%;
  background-color: #e56f51;
  top: 100%;
  left: 0%;
  transform: translate(-50%, -50%);
  transition: background-color 0s,
    width 1.1s cubic-bezier(0.5, 0, 0.05, 1.75) 0.5s,
    height 1.1s cubic-bezier(0.5, 0, 0.05, 1.75) 0.5s;
}
